$padding: 40px;
$width: 1020px;
$transition-time: 0.4s;
$border: 2px solid #000;

@font-face {
	font-family: 'I hate Comic Sans';
	src: local('I hate Comic Sans'), local('IhateComicSans'),
		url('./fonts/IhateComicSans.woff2') format('woff2'),
		url('./fonts/IhateComicSans.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Timeless';
	src: local('Timeless-Normal'),
		url('./fonts/Timeless-Normal.woff2') format('woff2'),
		url('./fonts/Timeless-Normal.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}



html,
body {
	height: 100%;
}

body {
	font-family: "Timeless", serif;
	color: #eeeeee;
	background: #000 url(./images/background.jpeg) center bottom;
	background-size: cover;
	background-attachment: fixed;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.4em;
	margin: 0;
	-ms-overflow-style: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	font-smooth: antialiased;

	@media(max-width: 768px) {
		font-size: 14px;
		overflow-x: hidden;
	}
}


ul,
li {
	width: auto;
}

ul {
	display: table;
	margin-left: auto;
	margin-right: auto;
}

li {
	text-align: left;
}

h1,
h2,
h3,
h4 {
	font-family: 'I hate Comic Sans';
	letter-spacing: 3px;
	text-transform: uppercase;
	font-weight: 100;
	line-height: 1;
	margin-top: 0;
	padding: 0;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

img:not(.img--fluid) {
	max-width: 100%;
	display: block;
	position: relative;
	margin: auto;
}

#comic_area img {
	height: auto !important;
	max-width: 100% !important;
}

#anfh {

	padding-left: $padding;
	padding-right: $padding;

	@media(max-width: 768px) {
		padding-left: $padding/2;
		padding-right: $padding/2;
	}

	@media(max-width: 520px) {
		padding: 0;
	}

}

.main {
	position: relative;

	&:before,
	&:after {
		content: " ";
		width: 5px;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 999;
		pointer-events: none;
		background: url(./images/border.png) center center repeat-y;

	}

	&:before {
		left: -5px;
	}

	&:after {
		right: -5px;
	}

	@media(max-width: 520px) {
		border: none;
	}

}

.container {
	position: relative;
	display: block;
	margin: auto;
	width: 100%;
	max-width: $width;

	box-sizing: border-box;
}

.btn {
	cursor: pointer;
	display: inline-block;
	width: auto;
	position: relative;
	line-height: 1;
	font-size: 18px;
	text-transform: uppercase;
	background: url(./images/btn-middle.png) center center repeat-x;
	padding: 40px 8px;
	margin: 0px 45px;
	color: #d3d3d3;

	&:after,
	&:before {
		content: "";
		display: block;
		width: 25px;
		position: absolute;
		margin-top: -1px;
		top: 0px;
		padding: 50px 0px;
	}

	&:before {
		background: url(./images/btn-left.png) center center no-repeat;
		left: -25px;
	}

	&:after {
		background: url(./images/btn-right.png) center center no-repeat;
		right: -25px;

	}

}

.header {
	background: url(./images/bg_black.jpg) center center;
	background-attachment: fixed;
	text-align: center;
	display: block;
	margin: 0;
	padding: 0;
	border-bottom: $border;

	img {
		margin: 0 auto;
		display: block;
	}
}

.row {
	position: relative;
	width: 100%;
	display: table;

	.col {
		display: table-cell;
		vertical-align: top;
		height: 100%;

		@media(max-width: 768px) {
			display: block;

		}
	}
}

a {
	color: #fff;
	text-decoration: none;
	-webkit-transition: all $transition-time ease-in-out;
	-moz-transition: all $transition-time ease-in-out;
	-o-transition: all $transition-time ease-in-out;
	transition: all $transition-time ease-in-out;

	a:hover,
	a:focus,
	a:visited {
		color: #fff;
	}
}

.menu {
	font-size: 17px;
	position: relative;
	background: url(./images/bg_red.jpg) center center;
	background-attachment: fixed;
	border-bottom: $border;

	@media(max-width: 768px) {
		font-size: 15px;
	}

	.menu-container {
		position: relative;
		width: auto;
		display: table;
		margin: auto;
		text-align: center;
		text-shadow: 0px 2px 2px black;

		span {
			color: #c1a998;
			padding-left: 4px;
			padding-right: 4px;

			@media(max-width: 768px) {
				padding-left: 2px;
				padding-right: 2px;

			}

			&:last-child {
				display: none !important;
				visibility: hidden;
			}
		}

		.menu-item {
			text-transform: uppercase;
			display: inline-block;
			position: relative;

			a {
				position: relative;
				display: block;
				padding: 10px;
				color: #c1a998;

				&:hover {
					color: #fff;
				}

				@media(max-width: 768px) {
					padding-left: 5px;
					padding-right: 5px;

				}
			}

			&.menu-item--orange {
				a {
					color: #e85b46;
				}
			}
		}

	}
}

.section {
	background: #000 center center;
	position: relative;
	background-attachment: fixed;

	&.section--red {
		background-image: url(./images/bg_red.jpg);
	}

	&.section--purple {
		background-image: url(./images/bg_purple.jpg);
	}

	&.section--black {
		background-image: url(./images/bg_black.jpg);
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		pointer-events: none;
		//background: radial-gradient(circle, rgba(255, 255, 255, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
		box-shadow: inset 0 0 100px 0px #000;
		mix-blend-mode: overlay;
		opacity: 1;
	}
}


.nav {
	display: block;
	position: relative;
	background: url(./images/bg_purple.jpg) center center;
	background-attachment: fixed;
	padding: 0px 15px;
	border-top: $border;
	border-bottom: $border;

	@media(max-width: 801px) {
		padding: 0px 10px;
	}

	.nav-container {
		position: relative;
		width: auto;
		display: table;
		margin: auto;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		a {
			position: relative;
			text-align: center;
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: 100px;
			display: block;
			//float: left;
			margin-left: 15px;
			padding: 0;
			margin-right: 15px;

			img {
				display: block
			}

			&:nth-child(3) {
				flex: 0 0 75px;
			}

			&:first-child {
				flex: 1 0 145px;
				text-align: left;

			}

			&:last-child {
				flex: 1 0 145px;
				text-align: right;
				//display: inline-block;
			}

			@media(max-width: 801px) {
				margin-left: 5px;
				margin-right: 5px;
				flex: 0 0 calc(17.793594306% - 10px);

				&:nth-child(3) {
					flex: 0 0 calc(12.8113879% - 10px);
				}

				&:first-child,
				&:last-child {
					flex: 0 0 calc(25.800711743% - 10px);
				}
			}
		}
	}
}

.content {
	position: relative;
}

.post_tools {
	font-size: 13px;
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: table;
	width: 100%;

	li {
		padding: 0;
		margin: 0;
		display: block;
		float: right;

		a {
			text-transform: uppercase;
			padding-left: 10px;
			color: #959595;
		}
	}
}

.post_comment {
	text-transform: uppercase;
	margin-top: $padding/2;
	font-size: 13px;
	position: relative;
	display: inline-block;
}

.blurb {
	background: url(./images/bg_black.jpg) center center;
	background-attachment: fixed;
	padding: $padding;
	width: 65%;
	border-left: $border;

	.blurp-wrapper {
		display: block;
		min-height: 400px;
	}

	h1 {
		color: #b7b7b7;
		font-size: 32px;
		margin-bottom: 1px;
	}

	.meta {
		font-size: 14px;
		text-transform: uppercase;
		color: #4e4e4e;
		letter-spacing: 2px;
		margin-bottom: 20px;
	}

	@media(max-width: 768px) {
		width: 100%;

	}

	.author-comment {
		padding-bottom: $padding/2;
	}

	.author-comment+.author-comment {
		padding-top: $padding/2;
		margin-top: $padding/2;
		border-top: 1px solid rgba(238, 238, 238, 0.1);
	}

	.author-comment--ad {
		padding: 0px !important;
		margin: 0px !important;
		border: none !important;
		display: none;

		#goo_ad,
		#spsrsj,
		*[id^="google_ads"] {
			display: none !important;
			height: 0px !important;
			visibility: hidden !important;
			opacity: 0 !important;
			padding: 0 !important;
			margin: 0 !important;
		}
	}

	.post_tools {
		padding-bottom: $padding/2;

		li {
			float: right;
		}
	}
}

h2 {
	font-family: 'I hate Comic Sans';
	font-size: 24px;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: 100;
	line-height: 1;
	padding: 0;
	text-align: center;
	//display: table;
	margin: 0 auto 20px;
	padding-bottom: 6px;
	color: #cdcdcd;
}

.cta {
	background: url(./images/bg_red.jpg) center center;
	background-attachment: fixed;
	width: 35%;
	padding: $padding $padding/2;
	text-align: center;
	border-left: $border;
	border-right: $border;

	@media(max-width: 768px) {
		width: 100%;

	}

	.dontate-img {
		text-align: center;
	}

	.donate-btns {
		position: relative;
		margin: -50px auto auto;
		display: table;

		>div {
			display: table-cell;
			width: 50%;
		}
	}

	a.donate-btn {
		position: relative;
		text-align: center;
		display: inline-block;
		margin: auto;
	}

	.author-comment--ad {
		margin-top: $padding;

		iframe {
			max-width: 100%;
		}
	}
}



.page-content {
	padding: $padding;
	min-height: calc(100vh - 218px - 42px - 52px);

	.img--fluid {
		margin-left: -$padding;
		margin-right: -$padding;

	}

	.page-content--block {
		background: url(./images/bg_black.jpg) center center;
		background-attachment: fixed;
		padding: 30px;
		border: 3px solid #606060;

		p:first-child {
			margin-top: 0px;
		}

		p:last-child {
			margin-bottom: 0px;
		}

		h1 {
			margin-bottom: 20px;
		}
	}

	.page-content--block+.img--fluid,
	.img--fluid+.page-content--block {
		margin-top: $padding;
	}

	h1,
	h2,
	h3,
	h3 {
		color: #b7b7b7;
	}

	h1:not(:first-child),
	h2:not(:first-child),
	h3:not(:first-child),
	h4:not(:first-child) {
		margin-top: $padding;
	}

	@media(max-width: 917px) {
		min-height: calc(100vh - calc(100vw * 0.2245) - 42px - 66px);
	}

	@media(max-width: 768px) {
		min-height: calc(100vh - calc(100vw * 0.262) - 42px - 66px);
	}

}

#comic_area,
#comic_area_inner {
	display: block !important;
}

#comic_area_inner {
	background: #000;
	text-align: center !important;

	a {
		display: block;
		position: relative;
		text-align: center;

		img {
			position: relative;
			display: block;
			margin: auto;
		}
	}
}

.jarallax {
	position: relative;
	z-index: 0;
}

.jarallax>.jarallax-img {
	position: absolute;
	object-fit: cover;
	font-family: 'object-fit: cover;';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.header,
.menu,
.nav,
.blurb,
.cta,
.footer,
.comments {
	position: relative;

	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		pointer-events: none;
		mix-blend-mode: overlay;
		opacity: 1;
	}
}

.header,
.menu,
.nav,
.footer {
	&:before {
		background: radial-gradient(circle, rgba(255, 255, 255, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
	}
}

.blurb,
.cta,
.comments {
	&:before {
		box-shadow: inset 0 0 100px 0px #000;
	}

}

.footer {
	background: url(./images/bg_red.jpg) center center;
	background-attachment: fixed;
	padding: 18px 20px;
	font-size: 14px;
	line-height: 1;
	text-shadow: 0px 2px 2px black;
	border-top: 2px solid #000;
	color: #c1a998;

	@media(max-width: 768px) {
		text-align: center;
	}

	@media(min-width: 769px) {
		.col:last-child {
			text-align: right;
		}
	}

	a {
		text-decoration: underline;
		color: #c1a998;
	}

}

.comments {
	background: url(./images/bg_purple.jpg) center center;
	background-attachment: fixed;
	padding: $padding;
	border-top: 2px solid #000;

	#goo_ad {
		margin-top: $padding/2;
		width: 100%;
		overflow: hidden;
	}

	.comment {
		box-shadow: 3px 3px 13px 2px rgba(0, 0, 0, 0.5);
		background: url(./images/bg_black.jpg) center center;
		padding: $padding/2;
		border: 2px solid #524f52;
		text-align: left;
		font-size: 13px;
		position: relative;
		line-height: 1.25;

		.row {
			.col:first-child {
				width: 100px + $padding/2;
				position: relative;
				vertical-align: middle;
				padding-right: $padding/2;

				@media(max-width: 768px) {
					padding-right: auto;
					display: table;
					width: 100%;

					>div {
						vertical-align: top;
						display: table-cell;

						&:first-child {
							width: 70px + $padding/2;
							padding-right: $padding/2;
						}
					}
				}

				&:after {
					content: "";
					display: block;
					width: 2px;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					background: url(./images/avatar-border.png) center center no-repeat;
					background-size: 100% 100%;

					@media(max-width: 768px) {
						display: none;
						width: 0;
						visibility: hidden;

					}
				}
			}

			.col:last-child {
				padding-left: $padding/2;

				@media(max-width: 768px) {
					padding-left: 0;
				}
			}
		}

		.username {
			padding-top: 14px;
			line-height: 1;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: bold;
			word-break: break-all;

			@media(max-width: 768px) {
				font-size: 13px;
				padding-top: $padding/2;
			}
		}

		.text {


			padding: $padding/2 0;
		}

		.date,
		.btn-reply {
			position: absolute;
			right: 0;
			text-align: right;
			line-height: 0.8;
		}

		.date {
			text-transform: uppercase;
			letter-spacing: 1.25px;
			top: 0;
			color: #7d7d7d;
			font-style: italic;

			@media(max-width: 768px) {
				left: 70px + $padding/2;
				text-align: left;
			}
		}

		.btn-reply {
			bottom: 0;

			a {
				text-transform: uppercase;
				color: #959595;
				margin-left: 8px;
				position: relative;

			}
		}

		&:not(:last-child) {
			margin-bottom: $padding/2;
		}

		&.reply {
			margin-left: 70px;

			@media(max-width: 768px) {
				margin-left: 30px;
			}
		}
	}

}

.text-center {
	text-align: center;
}

#archive {
	h1 {
		color: #b7b7b7;
	}

	h2 {
		font-family: "Timeless", serif;
		text-transform: uppercase;
		display: inline-block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		text-decoration: underline;
		color: #a2a1a2;
		font-size: 20px;
		letter-spacing: 0px;
	}

	.pages {
		display: flex;
		width: 700px;
		margin: auto;
		max-width: 100%;
		justify-content: center;
		flex-wrap: wrap;

		.page {
			position: relative;
			width: 100px;
			padding: 8px;
			text-align: center;
		}

		.chapter-title {
			width: 100%;
		}
	}

}

.menu+.nav {
	border-top: none;
}

/*
.google-ad {
	display: none;
}
*/


#comig-img-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: -10%;
	right: -10%;
	display: flex;
	align-items: center;
	text-align: center;

	&.top {
		align-items: flex-start;
	}

	&.bottom {
		align-items: flex-end;
	}

	img {
		margin: 0 auto;
	}
}

#comic_area,
#comic_area_inner {
	display: inline-block;
	margin: 0 auto;
	position: relative;
	text-align: left;
}

#comic_area_overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition: opacity 80ms;
	pointer-events: none;
}

#comic_area_overlay>* {
	pointer-events: all;
}

#comic_area_inner:hover #comic_area_overlay {
	opacity: 1;
}